// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  display: flex;
  height: 100vh;
  border-radius: unset;
  background-color: #181B2B;
  color: #fff;
}
.main-content .left-content {
  width: 264px;
  background-color: #202437;
  padding: 18px 16px;
}
.main-content .right-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-content .right-content .middle-main-content {
  display: flex;
  flex-direction: row;
}
.main-content .right-content .middle-main-content .middle-content {
  padding: 16px 20px 15px 20px;
  width: 68%;
}
.main-content .right-content .middle-main-content .right-main-content {
  width: 28.017%;
  background: #202437;
  padding: 34px 27px 0px 6px;
  height: auto;
  border-left: 1px solid #28304C;
}

.noData {
  padding: 15px;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,oBAAA;EACA,yBAAA;EACA,WAAA;AACJ;AACI;EACI,YAAA;EACA,yBAAA;EACA,kBAAA;AACR;AAEI;EACI,WAAA;EACA,aAAA;EACA,sBAAA;AAAR;AAEQ;EACI,aAAA;EACA,mBAAA;AAAZ;AAEY;EACI,4BAAA;EACA,UAAA;AAAhB;AAGY;EACI,cAAA;EACA,mBAAA;EACA,0BAAA;EACA,YAAA;EACA,8BAAA;AADhB;;AAOA;EACI,aAAA;EACA,YAAA;AAJJ","sourcesContent":[".main-content {\r\n    display: flex;\r\n    height: 100vh;\r\n    border-radius: unset;\r\n    background-color: #181B2B;\r\n    color: #fff;\r\n\r\n    .left-content {\r\n        width: 264px;\r\n        background-color: #202437;\r\n        padding: 18px 16px;\r\n    }\r\n\r\n    .right-content {\r\n        width: 100%;\r\n        display: flex;\r\n        flex-direction: column;\r\n\r\n        .middle-main-content {\r\n            display: flex;\r\n            flex-direction: row;\r\n\r\n            .middle-content {\r\n                padding: 16px 20px 15px 20px;\r\n                width: 68%;\r\n            }\r\n\r\n            .right-main-content {\r\n                width: 28.017%;\r\n                background: #202437;\r\n                padding: 34px 27px 0px 6px;\r\n                height: auto;\r\n                border-left: 1px solid #28304C;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n.noData {\r\n    padding: 15px;\r\n    width: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
