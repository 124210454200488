const menusData = [
  {
    id: 1,
    title: 'Home',
    icon: 'home',
    link: '/dashboard',
  },
  {
    id: 2,
    title: 'Video Feeds',
    icon: 'video',
    link: '/video-feeds',
  },
  {
    id: 3,
    title: 'Map Views',
    icon: 'map',
    link: '/map',
  },
  {
    id: 4,
    title: 'Alerts',
    icon: 'alert',
    link: '/alerts',
  },
  {
    id: 4,
    title: 'Statistics',
    icon: 'statistics',
    link: '/statistics',
  },
]

export default menusData;
