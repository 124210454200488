import { useState } from 'react';
import {
  Box, MenuItem, Select,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { actions } from '../../../../redux/Dashboard/slice';
import { TeleOperatorStatus } from '../../../../utils/enums';
import { constant } from '../../../../utils/constant';
import AlertImage from '../../../../assets/images/Alert.png';
import DownImage from '../../../../assets/images/Down.png';
import './styles.scss';

function CustomIcon() {
  return (
    <img src={DownImage} alt="DownImage" />
  );
}

function TeleDriversHeaderSection() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const selectedStatus = useAppSelector((state) => state.dashboardSlice.selectedOperatorStatus);

  const handleChange = (event: { target: { value: any; }; }) => {
    const { value } = event.target;
    dispatch(actions.setSelectedOption(value));
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box className="teleDriversHeaderSection">
      <Box>
        <span>
          <img src={AlertImage} alt="AlertImage" />
        </span>
        <span>
          {constant.TELEDRIVER}
        </span>
      </Box>
      <Box className="StatusDropdown">
        <Select
          value={selectedStatus}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': selectedStatus }}
          IconComponent={CustomIcon}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <MenuItem className="view-text">
            {constant.VIEW}
          </MenuItem>
          {Object.keys(TeleOperatorStatus).map((key) => (
            <MenuItem key={key} value={TeleOperatorStatus[key as keyof typeof TeleOperatorStatus]}>
              {TeleOperatorStatus[key as keyof typeof TeleOperatorStatus]}
            </MenuItem>
          ))}

        </Select>
      </Box>
    </Box>
  );
}

export default TeleDriversHeaderSection;
