interface Video {
  cameraVideo: string;
}

function CameraVideo({ cameraVideo }: Video) {
  return (
    <span className="camera-wrapper">
      <video width="100%" height="100%" autoPlay muted playsInline loop>
        <source src={cameraVideo} type="video/mp4" />
      </video>
    </span>
  )
}
export default CameraVideo;
