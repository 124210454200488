import { useEffect, useRef, useState } from 'react';
import {
  Box, Button,
} from '@mui/material';
import TeleDriversHeaderSection from './TeleDriverHeaderSection';
import RequestActionPopup from '../RequestsSection/RequestActionPopup';
import teledriverData from '../../../data/teledriverData';
import { useAppSelector } from '../../../store/hooks';
import { TeleOperatorStatus } from '../../../utils/enums';
import { GetHoveredImage } from '../../../utils/helper';
import { Teledriver } from '../../../redux/Dashboard/rootState';
import OperatorImage from '../../../assets/images/Operator.png';
import ThreeDotsGreyImage from '../../../assets/images/ThreeDotsGrey.png';
import './styles.scss';

function TeleDriversSection() {
  const [teledrivers, setTeledrivers] = useState<Teledriver[]>([]);
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
  const [popupStates, setPopupStates] = useState<boolean[]>([]);
  const selectedStatus = useAppSelector((state) => state.dashboardSlice.selectedOperatorStatus);
  const newRef = useRef<HTMLElement>(null);
  const requestActionPopup: boolean = true;

  const onClose = (index: number) => {
    const newPopupStates = [...popupStates];
    newPopupStates[index] = false;
    setPopupStates(newPopupStates);
  };

  const handleOutsideClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      onClose(Number(newRef?.current?.id));
    }
  };

  useEffect(() => {
    setTeledrivers(teledriverData);
    if (selectedStatus !== TeleOperatorStatus.ALL) {
      setTeledrivers(teledriverData.filter((item) => item.status === selectedStatus));
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.addEventListener('click', handleOutsideClick)
    }
  }, [selectedStatus]);

  const customClassName = (status: string) => ((status) === TeleOperatorStatus.AVAILABLE ? 'green' : status === TeleOperatorStatus.INSESSION ? 'orange' : 'purple')

  const handleMouseEnter = (rowId: number) => {
    setHoveredRowId(rowId);
  }

  const handleMouseLeave = () => {
    setHoveredRowId(null);
  }

  const handleActionBtnClick = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top: rect.top, right: rect.left });
    const newPopupStates = [...popupStates];
    newPopupStates[index] = !newPopupStates[index];
    setPopupStates(newPopupStates);
  };

  return (
    <>
      <TeleDriversHeaderSection />
      <Box className="teleDriversSection">
        <Box className="teleDriverCount">
          {`${teledrivers.length} Teledrivers`}
        </Box>
        {
          teledrivers.map((teledriver, index) => (
            <>
              <Box className="teleDriver">
                <Box className="teleDriverImage"><img src={teledriver.profileImage ? teledriver.profileImage : OperatorImage} alt="OperatorImage" /></Box>
                <Box className="teleDriverSec">
                  <Box className="teleDriverName">{teledriver.name}</Box>
                  <Box className="teleDriverDetails">
                    <Box className="wrapper">
                      <span className={customClassName(teledriver.status)} />
                      {teledriver.status}
                    </Box>
                    <Box className="wrapper">{teledriver.timing}</Box>
                    <Box>
                      {`${teledriver.scheduledSession} scheduled sessions`}
                    </Box>
                  </Box>
                </Box>
                <Button
                  className="actionButton teleDriverOption"
                  onMouseEnter={() => handleMouseEnter(teledriver.id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={(event) => handleActionBtnClick(index, event)}
                >
                  <img src={GetHoveredImage(teledriver.id, hoveredRowId!)} alt="Action" />
                </Button>
              </Box>
              {
                popupStates[index] && (
                  <Box ref={newRef} id={index.toString()} className="teledriversActionPopup">
                    <RequestActionPopup requestActionPopup={requestActionPopup} isOpen={popupStates[index]} onClose={() => onClose(index)} position={popupPosition} />
                  </Box>
                )
              }
            </>
          ))
        }
      </Box>
    </>
  );
}

export default TeleDriversSection;
