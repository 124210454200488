import { Box, Tab, Tabs } from '@mui/material';
import {
  SetStateAction, useEffect, useState,
} from 'react';
import ActiveSession from './ActiveSession';
import PendingSession from './PendingSession';
import ScheduledSession from './ScheduledSession';
import { RequestsData, SessionList } from '../../../redux/Dashboard/rootState';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { actions } from '../../../redux/Dashboard/slice';
import { RequestsStatus } from '../../../utils/enums';
import requestData from '../../../data/requestsData';
import dummyRequestsData from '../../../data/dummyRequestsData';
import requestsTab from '../../../utils/constant';
import { constant } from '../../../utils/constant';
import ArrowImage from '../../../assets/images/Arrow.png';
import OrangeArrowImage from '../../../assets/images/OrangeArrowImage.png';
import CalenderImage from '../../../assets/images/CalenderImage.png';
import OrangeCalenderImage from '../../../assets/images/OrangeCalender.png';
import PendingArrowImage from '../../../assets/images/PendingArrowImage.png';
import PendingGreyImage from '../../../assets/images/PendingIcon.png';
import './styles.scss';

interface Requests {
  handleComponentCalling: (val: RequestsData) => void;
}

function RequestsSection({ handleComponentCalling }: Requests) {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeRequests, setActiveRequests] = useState<RequestsData[]>([]);
  const [pendingRequests, setPendingRequests] = useState<RequestsData[]>([]);
  const [scheduledRequests, setScheduledRequests] = useState<RequestsData[]>([]);
  const sessionList = useAppSelector((state) => state.dashboardSlice.sessionList);
  const requestCount = useAppSelector((state) => state.dashboardSlice.requestData);
  const resetRequestCount = useAppSelector((state) => state.dashboardSlice.resetRequestCount);

  useEffect(() => {
    dispatch(actions.getVehicleSessionList());
  }, []);

  useEffect(() => {
    const newData = sessionList.map((item: SessionList, index: number) => {
      const dummyRequestsIndex = index % dummyRequestsData.length;
      const randomActivePending = dummyRequestsData[dummyRequestsIndex];
      const newStatus = item.status;
      const newItem = {
        ...randomActivePending,
        status: newStatus,
      };
      return newItem;
    });

    const mergedData = [...newData, ...requestData];
    dispatch(actions.setRequestData(mergedData));
  }, [sessionList]);

  useEffect(() => {
    setActiveRequests(requestCount.filter((item: RequestsData) => item?.status === RequestsStatus.ACTIVE));
    setPendingRequests(requestCount.filter((item: RequestsData) => item?.status === RequestsStatus.PENDING));
    setScheduledRequests(requestCount.filter((item: RequestsData) => item?.status === RequestsStatus.SCHEDULED));
  }, [requestCount]);

  useEffect(() => {
    if (resetRequestCount) {
      setTimeout(() => {
        dispatch(actions.setRequestData(requestData));
        dispatch(actions.resetRequestCount(false));
      }, 200);
    }
  }, [resetRequestCount]);

  const handleTabChange = (_event: any, newValue: SetStateAction<number>) => {
    setSelectedTab(newValue);
  };

  const generateTabLabel = (count: number, labelText: string, icon: string) => (
    <Box>
      <img src={icon} alt="Icon" />
      <Box className={`${labelText === requestsTab.SCHEDULED ? 'scheduled-flex' : ''}`}>
        <Box>
          <span className="count">{count}</span>
          <span className="labelText">{labelText}</span>
        </Box>
        {labelText === requestsTab.SCHEDULED && <Box className="predict-wrapper"><span className="predictedText">{constant.PREDICTED}</span></Box>}
      </Box>
    </Box>
  );

  const handleDialogEvent = (data: any) => {
    handleComponentCalling(data);
  }

  return (
    <Box className="requestSection">
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label={generateTabLabel(activeRequests.length, requestsTab.ACTIVE, selectedTab === 0 ? OrangeArrowImage : ArrowImage)} />
        <Tab label={generateTabLabel(pendingRequests.length, requestsTab.PENDING, selectedTab === 1 ? PendingArrowImage : PendingGreyImage)} />
        <Tab label={generateTabLabel(scheduledRequests.length, requestsTab.SCHEDULED, selectedTab === 2 ? OrangeCalenderImage : CalenderImage)} />
      </Tabs>

      <Box className="tabDetailsSection">
        {selectedTab === 0 && (
          <ActiveSession parentFunction={handleDialogEvent} />
        )}
        {selectedTab === 1 && (
          <PendingSession handleDialogEvent={handleDialogEvent} />
        )}
        {selectedTab === 2 && (
          <ScheduledSession selectedTab={selectedTab} handleDialogEvent={handleDialogEvent} />
        )}
      </Box>
    </Box>
  );
}
export default RequestsSection;
