import {
  Box, Dialog,
} from '@mui/material';
import { constant } from '../../../utils/constant';
import errorIcon from '../../../assets/images/error-icon.png';
import OperatorImage from '../../../assets/images/Operator.png';
import './styles.scss';
import { Button } from '@mui/base';

interface OperatorProps{
    parentFunctionCalling: (val: boolean) => void;
}
function OperatorBehaviourPopupSection({ parentFunctionCalling }: OperatorProps) {
  const isOpen = false;
  const onClose = () => {
    parentFunctionCalling(false);
  }

  return (
    <Dialog open className="operatorBehaviourPopupSection">
      <Box>
        <Box className="top-container">
          <span className="image-wrapper"><img src={errorIcon} alt="errorIcon" /></span>
          <Box className="heading">{constant.TITLE}</Box>
          <Box className="content-wrapper">
            <Box className="assignee">
              <span className="assignee-wrapper">
                <img src={OperatorImage} alt="OperatorImage" />
              </span>
              <span className="sub-heading">
                {constant.SUBTITLE}
              </span>
            </Box>
            <Box className="assignee">
              <span>{constant.VEHICLENAME}</span>
              <span className="white">{constant.LORAMIPSUM}</span>
            </Box>
            <Box className="assignee">
              <span>{constant.PLATENUMBERS}</span>
              <span className="white">{constant.NUMBER}</span>
            </Box>
            <Button className="dismiss-btn" onClick={onClose}>Dismiss</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default OperatorBehaviourPopupSection;
