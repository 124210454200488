import { combineReducers } from 'redux';
import { reducer as dashboardSlice } from '../redux/Dashboard/slice';

const rootReducer = combineReducers({
  dashboardSlice,
  // Add more reducers as needed
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
