// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuSection a.MuiListItem-root {
  padding-left: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  pointer-events: none;
  opacity: 0.24;
}

.menuSection a.MuiListItem-root.active {
  opacity: unset;
}

.menuSection a.MuiListItem-root .menuTitle span {
  font-size: 15px;
  font-family: "Poppins";
  line-height: 22px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.menuSection a.MuiListItem-root.active .menuTitle span {
  color: #fff !important;
  font-weight: 600;
}

.logoSection {
  padding: 8.96px 0;
  margin-bottom: 22px;
}

.MuiListItemIcon-root {
  min-width: 24px !important;
  margin-right: 20px;
}
.MuiListItemIcon-root img {
  width: 24px;
  height: 24px;
}

.logoSection img {
  cursor: pointer;
}

.menuSection a.MuiListItem-root.active {
  pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/SidebarSection/styles.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;EACA,oBAAA;EACA,oBAAA;EACA,aAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,+BAAA;AACJ;;AAEA;EACI,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,kBAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;AAER;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":[".menuSection a.MuiListItem-root {\r\n    padding-left: 4px;\r\n    padding-top: 20px;\r\n    padding-bottom: 20px;\r\n    pointer-events: none;\r\n    opacity: 0.24;\r\n}\r\n\r\n.menuSection a.MuiListItem-root.active{\r\n    opacity: unset;\r\n}\r\n\r\n.menuSection a.MuiListItem-root .menuTitle span {\r\n    font-size: 15px;\r\n    font-family: 'Poppins';\r\n    line-height: 22px;\r\n    font-weight: 400;\r\n    color: rgba(255, 255, 255, 0.70);\r\n}\r\n\r\n.menuSection a.MuiListItem-root.active .menuTitle span {\r\n    color: #fff !important;\r\n    font-weight: 600;\r\n}\r\n\r\n.logoSection {\r\n    padding: 8.96px 0;\r\n    margin-bottom: 22px;\r\n}\r\n\r\n.MuiListItemIcon-root {\r\n    min-width: 24px !important;\r\n    margin-right: 20px;\r\n    img{\r\n        width: 24px;\r\n        height: 24px;\r\n    }\r\n}\r\n\r\n.logoSection img {\r\n    cursor: pointer;\r\n}\r\n\r\n.menuSection a.MuiListItem-root.active {\r\n    pointer-events: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
