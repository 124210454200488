import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { Box } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Dashboard from './components/Dashboard';
import SideBarSection from './components/SidebarSection';
import HeaderSection from './components/HeaderSection';
import './App.scss';

function AppNavigation() {
  return (
    <BrowserRouter>
      <Box className="main-content">
        <Box className="left-content">
          <SideBarSection />
        </Box>
        <Box className="right-content">
          <HeaderSection />
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/dashboard" />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AppNavigation />
    </Provider>
  );
}

export default App;
