const requestsTab = {
  ACTIVE: 'Active Sessions',
  PENDING: 'Pending Requests',
  SCHEDULED: 'Scheduled Sessions',
}

export const path = {
  DASHBOARD: '/dashboard',
};

export const menusName = {
  HOME: 'home',
  VIDEO: 'video',
  MAP: 'map',
  ALERT: 'alert',
  STATISTICS: 'statistics',
}

export const constant = {
  TELEDRIVER: 'Teledrivers',
  TELEDRIVERHEAD: 'Teledriver',
  DURATION: 'Duration',
  PLATENUMBER: 'Plate',
  SERVICEID: 'Service Id',
  CONNECTIVITY: 'Connectivity',
  MONITOR: 'Monitor',
  ACTION: 'Action',
  REASSIGN: 'Reassign',
  SEEONMAP: 'See On Map',
  CALLDRIVER: 'Call Teledriver',
  ALERT: 'Alert',
  CLOSE: 'Close',
  WAITTIME: 'Wait time',
  ETATIME: 'ETA time',
  LICENSEPLATE: 'License Plate',
  ROUTE: 'Route',
  ASSIGNTO: 'Assign to',
  ANYONE: 'Anyone',
  ASSIGN: 'Assign',
  TASKTYPE: 'Task Type',
  VEHICLELOCATION: 'Vehicle Location',
  CUSTOMERNAME: 'Customers Name',
  TASK: 'Task',
  TIME: 'Time',
  ALERTS: 'Alerts',
  PREDICTED: '20 predicted',
  NOACTIVE: 'No Active Sessions',
  NOPENDING: 'No Pending Sessions',
  NOSCHEDULED: 'No Scheduled Sessions',
  NODATA: 'No data with last QUEUED event',
  NOREQUEST: 'There is no Request',
  VIEW: 'view',
  TITLE: 'Erratic Operator Behaviour',
  SUBTITLE: 'Nathalie Ellie Morgan',
  VEHICLENAME: 'Vehicle Name',
  PLATENUMBERS: 'Plate number',
  DISMISS: 'Dismiss',
  LORAMIPSUM: 'Loram ipsum',
  NUMBER: '123-456-789',
}
export const apiEndPoints = {
  GETLIST: '/session-api/sessions?verbose=true',
};

export default requestsTab;
