import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { RequestsStatus, TeleOperatorStatus } from '../../utils/enums';
import { RequestsData, SessionList } from './rootState';

const dashboardSlice = createSlice({
  name: 'inSession',
  initialState,
  reducers: {
    setSelectedOption: (state, actions: PayloadAction<TeleOperatorStatus>) => {
      state.selectedOperatorStatus = actions.payload;
    },
    setRequestData: (state, actions: PayloadAction<any>) => {
      state.requestData = actions.payload;
    },
    resetRequestData: (state) => {
      state.requestData = [];
    },
    resetAlertData: (state) => {
      state.alertsData = [];
    },
    setDefaultAlertsData: (state, actions: PayloadAction<any>) => {
      state.alertsData = actions.payload;
    },
    setResetSlide: (state, actions: PayloadAction<boolean>) => {
      state.resetSlide = actions.payload;
    },

    resetRequestCount: (state, actions: PayloadAction<boolean>) => {
      state.resetRequestCount = actions.payload;
    },

    getVehicleSessionList: (state) => {
      state.loading = true;
    },
    getVehicleSessionListSuccess: (state, action: PayloadAction<any>) => {
      state.sessionList = action.payload;
      state.loading = false;
    },
    getVehicleSessionListFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    getScenario: () => { },
    setScenario: (state, action) => {
      state.scenario = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardSlice;
