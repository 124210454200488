import { SetStateAction, useState } from 'react';
import {
  Box, Dialog, Tab, Tabs,
} from '@mui/material';
import { AlertsData, RequestsData } from '../../../redux/Dashboard/rootState';
import { CameraStatus, PRIORITY } from '../../../utils/enums';
import { constant } from '../../../utils/constant';
import CameraVideo from './CameraVideo/index';
import AssigneePopupSection from '../../common/AssigneePopupSection';
import openEyeIcon from '../../../assets/images/open-eye-icon.png';
import messengerIcon from '../../../assets/images/messenger-icon.png';
import starIcon from '../../../assets/images/star-icon.png';
import closeIcon from '../../../assets/images/close-icon.png';
import mapImage from '../../../assets/images/alert-map-image.png';
import threeDotIcon from '../../../assets/images/three-dot-icon.png';
import progressBarImage from '../../../assets/images/progress-bar-image.png';
import arrowRightIcon from '../../../assets/images/arrow-right-icon.png';
import './styles.scss';

interface SessionInfoPanel {
  sessionInfoPanelData: AlertsData | RequestsData;
  parentFunction: (val: boolean) => void;
}

function SessionInfoPanelSection({ sessionInfoPanelData, parentFunction }: SessionInfoPanel) {
  const isOpen = true;
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAssigneePopup, setAssigneeValue] = useState(false);
  const clasName = 'sessionInfoPanelAssigneeSection';
  const [assigneePopupPosition, setAssigneePopupPosition] = useState({ top: 0, right: 0 });
  const handleTabChange = (_event: any, newValue: SetStateAction<number>) => {
    setSelectedTab(newValue);
  };

  const generateTabLabel = (labelText: string) => (
    <Box>
      <span className="labelText">{labelText}</span>
    </Box>
  );

  const onClose = () => {
    parentFunction(false);
  }

  const openAssigneePopup = (e: any) => {
    e.stopPropagation();
    setAssigneeValue(true)
  }

  const onCloseAssigneePopup = (val: boolean) => {
    setAssigneeValue(false)
  }

  const onDailogClickEvent = (e: any) => {
    onClose();
  }

  const onHandleClickEvent = (event: any) => {
    event.stopPropagation();
  }

  return (
    <Dialog open={isOpen} className="SessionInfoPanelSection" onClick={(event) => onDailogClickEvent(event)}>
      <Box onClick={(event) => onHandleClickEvent(event)}>
        <Box className="top-container">
          <Box className="top-wrapper">
            <Box className="left-wrapper">
              <span>{constant.ALERT}</span>
            </Box>
            <Box className="right-wrapper">
              <span className="image-wrapper">
                <img src={openEyeIcon} alt="openEyeIcon" />
              </span>
              <span className="monitor white-text">{constant.MONITOR}</span>
              <span className="image-wrapper messenger">
                <img src={messengerIcon} alt="messengerIcon" />
              </span>
              <span className="image-wrapper">
                <img src={starIcon} alt="starIcon" />
              </span>
              <Box style={{ padding: '8px 0px 8px 14px' }}>
                <Box className="close-btn" onClick={onClose}>
                  <span className="image-wrapper">
                    <img src={closeIcon} alt="closeIcon" />
                  </span>
                  <span className="white-text">{constant.CLOSE}</span>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="emergency-text white-text">
            {(sessionInfoPanelData as AlertsData).title}
            {' '}
            {(sessionInfoPanelData as RequestsData).taskType}
          </Box>
          <Box className="bottom-wrapper">
            <Box className="left">
              <Box className={`priority-wrapper ${sessionInfoPanelData.priority === PRIORITY.HIGH ? 'high' : sessionInfoPanelData.priority === PRIORITY.MEDIUM ? 'medium' : 'low'}`}>
                <span className="circle-icon" />
                <span className="priority">{sessionInfoPanelData.priority}</span>
              </Box>
              <Box>
                <span className="wait-time">{constant.WAITTIME}</span>
                <span className="white-text timer">
                  {sessionInfoPanelData.waitTime}
                  {' '}
                  min
                </span>
              </Box>
              <Box>
                <span className="ETA-time">{constant.ETATIME}</span>
                <span className="white-text timer">
                  {sessionInfoPanelData.etaTime}
                  {' '}
                  min
                </span>
              </Box>
            </Box>
            <Box className="right">
              <span className="license-plate">{constant.LICENSEPLATE}</span>
              <span className="license-plate-number">{sessionInfoPanelData.plateNumber}</span>
            </Box>
          </Box>
        </Box>
        <Box className="middle-container">
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label={generateTabLabel(CameraStatus.Front)} />
            <Tab label={generateTabLabel(CameraStatus.Back)} />
            <Tab label={generateTabLabel(CameraStatus.Left)} />
            <Tab label={generateTabLabel(CameraStatus.Right)} />
          </Tabs>

          <Box className="tabCameraSection">
            <Box>
              {selectedTab === 0 && (
                <CameraVideo cameraVideo={sessionInfoPanelData.cameraVideos.front} />
              )}
              {selectedTab === 1 && (
                <CameraVideo cameraVideo={sessionInfoPanelData.cameraVideos.back} />
              )}
              {selectedTab === 2 && (
                <CameraVideo cameraVideo={sessionInfoPanelData.cameraVideos.left} />
              )}
              {selectedTab === 3 && (
                <CameraVideo cameraVideo={sessionInfoPanelData.cameraVideos.right} />
              )}
            </Box>
            <Box>
              <Box className="route-wrapper">
                <Box className="left-route">
                  <span className="white-text route">{constant.ROUTE}</span>
                </Box>
                <Box className="right-route">
                  <span>{sessionInfoPanelData.startPoint}</span>
                  <span className="image-route"><img src={arrowRightIcon} alt="arrowRightIcon" /></span>
                  <span>{sessionInfoPanelData.endPoint}</span>
                </Box>
              </Box>
              <Box className="progress-bar-warpper">
                <img
                  src={sessionInfoPanelData.progressImage ? sessionInfoPanelData.progressImage : progressBarImage}
                  alt="progressBarImage"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="bottom-container">
          <Box className="map-wrapper">
            <img src={sessionInfoPanelData.mapImage ? sessionInfoPanelData.mapImage : mapImage} alt="mapImage" />
          </Box>
          <Box className="assign-wrapper">
            <span className="white-text assign-to">{constant.ASSIGNTO}</span>
            <Box className="anyone" onClick={(event) => openAssigneePopup(event)}>
              <span>{constant.ANYONE}</span>
              <span>
                <img src={threeDotIcon} alt="threeDotIcon" />
                {' '}
              </span>
            </Box>
            <Box className="assign-btn"><span className="white-text">{constant.ASSIGN}</span></Box>
          </Box>
        </Box>
        <Box>
          {
            isAssigneePopup ? (
              <AssigneePopupSection selectedTab={clasName} parentFunctionCalling={onCloseAssigneePopup} assingeePosition={assigneePopupPosition} />
            )
              : ('')
          }

        </Box>
      </Box>
    </Dialog>
  )
}

export default SessionInfoPanelSection;
