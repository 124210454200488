import {
  Box, Dialog, FormControl, OutlinedInput, TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Teledriver, Position } from '../../../redux/Dashboard/rootState';
import teledriverData from '../../../data/teledriverData';
import { constant } from '../../../utils/constant';
import { TeleOperatorStatus } from '../../../utils/enums';
import crossIcon from '../../../assets/images/cross-icon.png';
import OperatorImage from '../../../assets/images/Operator.png';
import rightCheckIcon from '../../../assets/images/right-check-icon.png';
import exclamationOrangeIcon from '../../../assets/images/exclamation-orange-icon.png';
import exclamationRedIcon from '../../../assets/images/exclamation-red-icon.png';
import searchIcon from '../../../assets/images/search-icon.png';
import './styles.scss'

interface AssigneeProps {
  parentFunctionCalling: (val: boolean) => void;
  selectedTab: string;
  assingeePosition: Position;
}

function AssigneePopupSection({ parentFunctionCalling, selectedTab, assingeePosition }: AssigneeProps) {
  const isOpen = true;
  const [teledrivers, setTeledrivers] = useState<Teledriver[]>([]);
  const { top } = assingeePosition;
  const newBottomPosition = top - 458;

  useEffect(() => {
    setTeledrivers(teledriverData);
    if (selectedTab === 'scheduledAssigneeSection') {
      const sheet = document.createElement('style')
      sheet.innerHTML = `.${selectedTab} .MuiDialog-container { top : ${newBottomPosition}px; align-items: flex-start; }`;
      document.body.appendChild(sheet);
    }
  }, []);

  const onClose = () => {
    parentFunctionCalling(false);
  }

  return (
    <Dialog open={isOpen} className={`AssigneePopupSection ${selectedTab}`}>
      <Box className="assignee-header">
        <Box className="assign-content-wrapper">
          <span>{constant.ASSIGNTO}</span>
          <span
            className="image-wrapper"
            onClick={onClose}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                onClose();
              }
            }}
          >
            <img src={crossIcon} alt="crossIcon" />
          </span>
        </Box>
        <Box className="search-box-wrapper">
          <FormControl>
            <OutlinedInput
              id="outlined-adornment-password"
              type="search"
              startAdornment={
                <img src={searchIcon} alt="searchIcon" />
              }
              placeholder="Search"
            />
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Box className="assignee-list-wrapper">
          {
            teledrivers.map((item) => (
              <Box className="assignee-item">
                <Box className="teleDriverImage">
                  <img src={item.profileImage ? item.profileImage : OperatorImage} alt="OperatorImage" />
                </Box>
                <Box className="">
                  <Box className="assignee-title"><span>{item.name}</span></Box>
                  <Box className="assignee-text">
                    <span className="circle-icon">
                      <img src={item.status === TeleOperatorStatus.AVAILABLE ? rightCheckIcon : item.status === TeleOperatorStatus.INSESSION ? exclamationOrangeIcon : exclamationRedIcon} alt="icon" />
                    </span>
                    <span>
                      {item.status}
                      {item.status === TeleOperatorStatus.AVAILABLE ? 'for the session' : ''}
                    </span>
                  </Box>
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
    </Dialog>
  )
}

export default AssigneePopupSection;
