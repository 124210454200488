import moment from 'moment';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface TimerProps{
    timer: string;
}

export default function SessionTimer({ timer }: TimerProps) {
  const [seconds, setSeconds] = useState<number>(moment.duration(timer).asSeconds());
  const formattedTime: string = moment.utc(seconds * 1000).format('HH:mm:ss');

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <Box className="timer">
      <span className="timerWrapper">
        {formattedTime}
      </span>
    </Box>
  );
}
