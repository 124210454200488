import video from '../assets/video/Freight.mp4';
import driver1 from '../assets/profileImages/man1.jpg';

const dummyRequestsData = [
  {
    id: 1,
    teleDriver: 'Miaaaa Wilson',
    teledriverProfileImage: driver1,
    serviceId: '1',
    plateNumber: 'X7D-9K2',
    address: '303188 Kessler Burg',
    duration: '00:04:30',
    connectivityStatus: 'Connected',
    status: 'Pending',
    waitTime: '00:00',
    etaTime: '01:20',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
]

export default dummyRequestsData;
