import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { actions } from '../../../redux/Dashboard/slice';
import { RootState } from '../../../store/rootReducer';
import { AlertsData } from '../../../redux/Dashboard/rootState';
import SessionTimer from '../../common/SessionTimer';
import data from '../../../data/alertsData';
import { PRIORITY } from '../../../utils/enums';
import { constant } from '../../../utils/constant';
import 'react-multi-carousel/lib/styles.css';
import locationImage from '../../../assets/images/location-icon.png';
import timerImage from '../../../assets/images/timer-icon.png';
import alertImage from '../../../assets/images/alert-icon.png';
import './styles.scss';

interface Alert {
  parentFunction: (val: any) => void;
}

function AlertSection({ parentFunction }: Alert) {
  const alertItems: AlertsData[] = useAppSelector((state: RootState) => state.dashboardSlice.alertsData)
  const resetSlide: boolean = useAppSelector((state: RootState) => state.dashboardSlice.resetSlide)
  const dispatch = useAppDispatch();
  const newRef = useRef<any>(null);
  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 3.1,
      slidesToSlide: 3,
    },
  }
  const openSessionInfoPanel = (item: any) => {
    parentFunction(item);
    const val: HTMLElement | null = document.getElementById(`alerts_${item?.id}`);
    if (val) {
      val.classList.add('selected');
    }
  }

  useEffect(() => {
    dispatch(actions.setDefaultAlertsData([...data]));
  }, [data])

  useEffect(() => {
    if (resetSlide) {
      newRef.current.goToSlide(0);
      dispatch(actions.setDefaultAlertsData([...data]));
      dispatch(actions.setResetSlide(false));
    }
  }, [resetSlide]);

  const preventDefault = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const handleDragStart = (event: { preventDefault: () => void; }) => {
    preventDefault(event);
  };

  const handleDrop = (event: { preventDefault: () => void; }) => {
    preventDefault(event);
  };

  return (
    <Box className="alerts-container">
      <Box className="alert-top-panel">
        <Box className="d-flex">
          <Box className="alert-icon"><img src={alertImage} alt="alertImage" /></Box>
          <span className="alert-title">
            {constant.ALERTS}
            <span>
              (
              <span className="alert-count">{`${alertItems.length}`}</span>
              )
            </span>
          </span>
        </Box>
      </Box>
      <Box className="custom-carousel">
        <Carousel ref={newRef} swipeable={false} draggable={false} responsive={responsive}>
          {alertItems.map((item, index) => (
            <Box
              className="alert-wrapper"
              onClick={() => openSessionInfoPanel(item)}
              onDragOver={preventDefault}
              onDragEnter={preventDefault}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
              id={`alerts_${item.id}`}
            >
              <Box className="alert-top-items">
                <Box className="left">
                  <span className="carousel-title">{item.title}</span>
                  <span className="carousel-plate-number">
                    {item.plateNumber}
                  </span>
                </Box>
                <Box className={`right ${item.priority === PRIORITY.HIGH ? 'high' : item.priority === PRIORITY.MEDIUM ? 'medium' : 'low'}`}>
                  <span className="circle-icon" />
                  <span className="priority">{item.priority}</span>
                </Box>
              </Box>
              <Box className="alert-bottom-items">
                <Box className="d-flex">
                  <Box className="image-wrapper"><img src={locationImage} alt="locationImage" /></Box>
                  <span>{item.address}</span>
                </Box>

                <Box className="d-flex">
                  <Box className="image-wrapper"><img src={timerImage} alt="timerImage" /></Box>
                  <SessionTimer timer={item.startTimer} />
                </Box>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
}

export default AlertSection;
