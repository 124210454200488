import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { MenuItem } from '../../redux/Dashboard/rootState';
import menuData from '../../data/menuData';
import { menusName } from '../../utils/constant';
import LogoImage from '../../assets/images/Logo.png';
import HomeIcon from '../../assets/images/Home.png';
import VideoIcon from '../../assets/images/Feed.png';
import MapIcon from '../../assets/images/Map.png';
import AlertsIcon from '../../assets/images/Alerts.png';
import StatisticsIcon from '../../assets/images/Statistics.png';
import './styles.scss';

function Sidebar() {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    setMenuItems(menuData);
  }, []);

  const getIconByName = (iconName: string) => {
    switch (iconName) {
      case menusName.HOME:
        return HomeIcon;
      case menusName.VIDEO:
        return VideoIcon;
      case menusName.MAP:
        return MapIcon;
      case menusName.ALERT:
        return AlertsIcon;
      case menusName.STATISTICS:
        return StatisticsIcon;
      default:
        return '';
    }
  };

  return (
    <Box className="sidebar">
      <Box className="logoSection">
        <img src={LogoImage} alt="LogoImage" />
      </Box>
      <Box className="menuSection">
        <List component="nav">
          {menuItems.map((menuItem, index) => (
            <ListItem className={index === 0 ? 'active' : ''} key={menuItem.id} component={Link} to={menuItem.link}>
              <ListItemIcon>
                <img
                  src={getIconByName(menuItem.icon)}
                  className="icon"
                  alt={menuItem.icon}
                />
              </ListItemIcon>
              <ListItemText primary={menuItem.title} className="menuTitle" />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default Sidebar;
