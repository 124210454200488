// eslint-disable-next-line import/prefer-default-export
export enum TeleOperatorStatus {
    ALL = 'All',
    AVAILABLE = 'Available',
    INSESSION = 'In Session',
    ONBREAK = 'On Break'
}

export enum RequestsStatus {
    ACTIVE = 'Active',
    PENDING = 'Pending',
    SCHEDULED = 'Scheduled'
}

export enum SessionsStatus {
    QUEUED = 'QUEUED',
    ESTABLISHED = 'ESTABLISHED',
}

export enum ConnectionStatus {
    CONNECTED = 'Connected',
    WARNING = 'Warning',
    ERROR = 'Error'
}

export enum PriorityStatus {
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low'
}

export enum CameraStatus {
    Front = 'Front camera',
    Back = 'Back camera',
    Left = 'Left camera',
    Right = 'Right camera'
}

export enum PRIORITY {
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low'
}
