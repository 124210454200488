import video from '../assets/video/Freight.mp4'
import path1 from '../assets/mapImages/path1.png'
import path2 from '../assets/mapImages/path2.png'
import path3 from '../assets/mapImages/path3.png'
import path4 from '../assets/mapImages/path4.png'
import path5 from '../assets/mapImages/path5.png'
import progress1 from '../assets/mapImages/progress1.png'
import progress2 from '../assets/mapImages/progress2.png'
import progress3 from '../assets/mapImages/progress3.png'
import progress4 from '../assets/mapImages/progress4.png'
import progress5 from '../assets/mapImages/progress5.png'

const alertsData = [
  {
    id: 1,
    title: 'Vehicle Stuck Without Reception',
    priority: 'High',
    plateNumber: 'CSW-742',
    address: '967 Park St, Elko, NV...',
    startTimer: '00:07:04',
    waitTime: '07:04',
    etaTime: '00:04:23',
    startPoint: '703 Elm St, Elko, NV',
    endPoint: '939 Main St, Elko, NV',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: progress1,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 2,
    title: 'Sensor Calibration Required',
    priority: 'Medium',
    plateNumber: 'BRX-954',
    address: '250 Pine St, Reno, NV...',
    startTimer: '00:01:14',
    waitTime: '01:14',
    etaTime: '00:03:47',
    startPoint: '202 Lake St, Reno, NV',
    endPoint: '913 Oak St, Reno, NV',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 3,
    title: 'Weak Antenna Signal',
    priority: 'Medium',
    plateNumber: 'LCJ-512',
    address: '162 Cedar St, Fernley, NV...',
    startTimer: '00:06:00',
    waitTime: '06:00',
    etaTime: '00:06:02',
    startPoint: '542 Main St, Fernley, NV',
    endPoint: '782 Pine St, Fernley, NV',
    mapImage: path2,
    progressImage: progress3,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 4,
    title: '[Verizon] Low Performance Detected',
    priority: 'Low',
    plateNumber: 'BFS-592',
    address: '319 Pine St, Henderson, NV...',
    startTimer: '00:03:18',
    waitTime: '03:18',
    etaTime: '00:08:38',
    startPoint: '328 Elm St, Henderson, NV',
    endPoint: '994 Lake St, Henderson, NV',
    mapImage: path4,
    progressImage: progress4,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 5,
    title: 'Data Inconsistency Detected',
    priority: 'Low',
    plateNumber: 'OKX-101',
    address: '168 Elm St, Reno, NV...',
    startTimer: '00:01:25',
    waitTime: '01:25',
    etaTime: '00:07:57',
    startPoint: '992 Cedar St, Reno, NV',
    endPoint: '432 Pine St, Reno, NV',
    mapImage: path5,
    progressImage: progress5,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 6,
    title: 'Vehicle Right Side Bulb Broken',
    priority: 'Low',
    plateNumber: 'MKN-045',
    address: '460 Willow St, Sparks, NV...',
    startTimer: '00:08:09',
    waitTime: '08:09',
    etaTime: '00:03:54',
    startPoint: '340 Washington St, Sparks, NV',
    endPoint: '521 Washington St, Sparks, NV',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 7,
    title: 'Vehicle Software Update Required',
    priority: 'Low',
    plateNumber: 'ITU-733',
    address: '222 Lake St, Boulder City, NV...',
    startTimer: '00:01:40',
    waitTime: '01:40',
    etaTime: '00:06:02',
    startPoint: '594 Maple St, Boulder City, NV',
    endPoint: '957 Maple St, Boulder City, NV',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 8,
    title: 'Teleoperator Experiencing Minor Control Lag',
    priority: 'Low',
    plateNumber: 'HTA-254',
    address: '464 Maple St, Fernley, NV...',
    startTimer: '00:05:55',
    waitTime: '05:55',
    etaTime: '00:02:41',
    startPoint: '645 Pine St, Fernley, NV',
    endPoint: '400 Oak St, Fernley, NV',
    mapImage: path5,
    progressImage: progress5,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
]

export default alertsData;
