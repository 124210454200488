import { Box, Button } from '@mui/material';
import { useAppDispatch } from '../../store/hooks';
import { actions } from '../../redux/Dashboard/slice';
import dummyPersonImage from '../../assets/images/Dummy.png';
import downArrow from '../../assets/images/DownArrow.png';
import settingImage from '../../assets/images/Settings.png';
import './styles.scss';

function HeaderSection() {
  const dispatch = useAppDispatch();
  const handleActionBtnClick = () => {
    dispatch(actions.resetAlertData());
    dispatch(actions.setResetSlide(true));
    dispatch(actions.resetRequestData());
    dispatch(actions.resetRequestCount(true));
  }

  return (
    <Box className="headerSection">
      <Box className="headerLeftSection">
        <Box className="breadCrumbs">
          Home
        </Box>
      </Box>
      <Box className="headerRightSection">
        <Button type="button" className="profile-button">
          <Box className="profile-info">
            <img
              src={dummyPersonImage}
              className="demo_profile_img"
              alt="demo_profile_img"
            />
            <Box className="user-name-text">
              Ottopia
            </Box>
          </Box>
          <span className="arrow-wrapper">
            <img
              src={downArrow}
              className="arrow_down_logo"
              alt="arrow_down_logo"
            />
          </span>
        </Button>
        <Button className="settingButton" onDoubleClick={handleActionBtnClick}>
          <img src={settingImage} alt="settingImage" />
        </Button>
      </Box>
    </Box>
  );
}

export default HeaderSection;
