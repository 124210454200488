import { ConnectionStatus, RequestsStatus, PriorityStatus } from './enums';
import ConnectedImage from '../assets/images/Connected.png';
import WarningImage from '../assets/images/Warning.png';
import ErrorImage from '../assets/images/Error.png';
import OrangeDots from '../assets/images/ThreeDotsOrange.png';
import GreyDots from '../assets/images/ThreeDotsGrey.png';
import RightArrowImage from '../assets/images/RightArrow.png';
import OrangeArrowImage from '../assets/images/OrangeArrow.png';
import CompletedLeftImage from '../assets/images/CompletedLeft.png';
import ErrorLeftImage from '../assets/images/ErrorLeft.png';
import WarningLeftImage from '../assets/images/WarningLeft.png';
import { RequestsData, SessionList } from '../redux/Dashboard/rootState';

export function GetConnctionStatusImage(connectivityStatus: string) {
  return connectivityStatus === ConnectionStatus.CONNECTED ? ConnectedImage : connectivityStatus === ConnectionStatus.WARNING ? WarningImage : ErrorImage;
}

export function GetLeftBorder(connectivityStatus: string) {
  return connectivityStatus === PriorityStatus.LOW ? CompletedLeftImage : connectivityStatus === PriorityStatus.MEDIUM ? WarningLeftImage : ErrorLeftImage;
}

export function GetHoveredImage(requestId: number, hoveredRowId: number) {
  return hoveredRowId === requestId ? OrangeDots : GreyDots;
}

export function GetMonitorButtonText(requestId: number, hoveredMonitorRowId: number) {
  return hoveredMonitorRowId === requestId ? OrangeArrowImage : RightArrowImage;
}

export function UpdateRequestList(requestData: RequestsData[], sessionList: SessionList[]) {
  const activePendingRequests = requestData.filter(
    (item) => item.status === RequestsStatus.ACTIVE || item.status === RequestsStatus.PENDING,
  );
  const newData = activePendingRequests.map((item) => {
    const newItem = { ...item };
    const statusOptions = sessionList;
    const randomStatus = statusOptions[Math.floor(Math.random() * statusOptions.length)];
    newItem.status = randomStatus.status;
    return newItem;
  });

  const scheduledData = requestData.filter((item) => item.status === RequestsStatus.SCHEDULED);
  const mergedData = [...newData, ...scheduledData];

  if (activePendingRequests.length < sessionList.length) {
    const numOfNewItems = sessionList.length - activePendingRequests.length;
    let i = 0;
    while (i < numOfNewItems) {
      const randomActivePending = activePendingRequests[Math.floor(Math.random() * activePendingRequests.length)];
      const newStatus = sessionList[activePendingRequests.length + i].status;

      const newItem = {
        ...randomActivePending,
        status: newStatus,
      };

      mergedData.push(newItem);
      i += 1;
    }
  }

  return mergedData;
}
