import woman1 from '../assets/profileImages/woman1.jpg';
import woman2 from '../assets/profileImages/woman2.jpg';
import woman3 from '../assets/profileImages/woman3.jpg';
import woman4 from '../assets/profileImages/woman4.png';
import woman5 from '../assets/profileImages/woman5.jpg';
import woman7 from '../assets/profileImages/woman7.png';
import woman8 from '../assets/profileImages/woman8.png';
import woman9 from '../assets/profileImages/woman9.png';
import woman10 from '../assets/profileImages/woman10.png';
import woman11 from '../assets/profileImages/woman11.png';
import woman12 from '../assets/profileImages/woman12.png';
import woman13 from '../assets/profileImages/woman13.png';
import woman14 from '../assets/profileImages/woman14.png';
import woman15 from '../assets/profileImages/woman15.png';

import man1 from '../assets/profileImages/man1.jpg';
import man2 from '../assets/profileImages/man2.jpg';
import man3 from '../assets/profileImages/man3.png';
import man4 from '../assets/profileImages/man4.png';
import man5 from '../assets/profileImages/man5.jpg';
import man6 from '../assets/profileImages/man6.jpg';
import man7 from '../assets/profileImages/man7.png';
import man8 from '../assets/profileImages/man8.png';

const telewomansData = [
  {
    id: 1,
    profileImage: woman1,
    name: 'Lily White',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 2,
    profileImage: woman2,
    name: 'Mia Wilson',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '3',
  },
  {
    id: 3,
    profileImage: man1,
    name: 'James Smith',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '5',
  },
  {
    id: 4,
    profileImage: man2,
    name: 'Avery Young',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 5,
    profileImage: man3,
    name: 'Aiden Jackson',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '4',
  },
  {
    id: 6,
    profileImage: woman3,
    name: 'Jackson Wright',
    status: 'On Break',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 7,
    profileImage: woman4,
    name: 'Ava Chen',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '3',
  },
  {
    id: 8,
    profileImage: woman5,
    name: 'Avery Young',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 9,
    profileImage: woman8,
    name: 'Amelia Brown',
    status: 'On Break',
    timing: '6:00-12:00',
    scheduledSession: '3',
  },
  {
    id: 10,
    profileImage: woman7,
    name: 'Luna Martinez',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '2',
  },
  {
    id: 11,
    profileImage: woman9,
    name: 'Scarlett Carter',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '5',
  },
  {
    id: 12,
    profileImage: woman10,
    name: 'Isabella Martinez',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '2',
  },
  {
    id: 13,
    profileImage: woman11,
    name: 'Amy Ford',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 14,
    profileImage: woman12,
    name: 'Chloe Martin',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 15,
    profileImage: man4,
    name: 'Aiden Jackson',
    status: 'On Break',
    timing: '6:00-12:00',
    scheduledSession: '3',
  },
  {
    id: 16,
    profileImage: woman13,
    name: 'Olivia Jones',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '4',
  },
  {
    id: 17,
    profileImage: man5,
    name: 'Michael Robinson',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '1',
  },
  {
    id: 18,
    profileImage: woman14,
    name: 'Emily Hall',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '5',
  },
  {
    id: 19,
    profileImage: woman15,
    name: 'Grace Walker',
    status: 'On Break',
    timing: '6:00-12:00',
    scheduledSession: '3',
  },
  {
    id: 20,
    profileImage: man6,
    name: 'Daniel Perez',
    status: 'In Session',
    timing: '6:00-12:00',
    scheduledSession: '2',
  },
  {
    id: 21,
    profileImage: man7,
    name: 'Harper Anderson',
    status: 'Available',
    timing: '6:00-12:00',
    scheduledSession: '3',
  },
  {
    id: 22,
    profileImage: man8,
    name: 'Mason Davis',
    status: 'On Break',
    timing: '6:00-12:00',
    scheduledSession: '4',
  },
]

export default telewomansData;
