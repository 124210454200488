import video from '../assets/video/Freight.mp4';
import woman1 from '../assets/profileImages/woman1.jpg';
import woman2 from '../assets/profileImages/woman2.jpg';
import woman3 from '../assets/profileImages/woman3.jpg';
import woman4 from '../assets/profileImages/woman4.png';
import woman5 from '../assets/profileImages/woman5.jpg';
import woman7 from '../assets/profileImages/woman7.png';
import woman8 from '../assets/profileImages/woman8.png';
import woman9 from '../assets/profileImages/woman9.png';
import woman10 from '../assets/profileImages/woman10.png';
import woman11 from '../assets/profileImages/woman11.png';
import woman12 from '../assets/profileImages/woman12.png';
import woman13 from '../assets/profileImages/woman13.png';
import woman14 from '../assets/profileImages/woman14.png';
import woman15 from '../assets/profileImages/woman15.png';

import man1 from '../assets/profileImages/man1.jpg';
import man2 from '../assets/profileImages/man2.jpg';
import man3 from '../assets/profileImages/man3.png';
import man4 from '../assets/profileImages/man4.png';
import man5 from '../assets/profileImages/man5.jpg';
import man6 from '../assets/profileImages/man6.jpg';
import man7 from '../assets/profileImages/man7.png';
import man8 from '../assets/profileImages/man8.png';

import path1 from '../assets/mapImages/path1.png'
import path2 from '../assets/mapImages/path2.png'
import path3 from '../assets/mapImages/path3.png'
import path4 from '../assets/mapImages/path4.png'
import path5 from '../assets/mapImages/path5.png'
import progress1 from '../assets/mapImages/progress1.png'
import progress2 from '../assets/mapImages/progress2.png'
import progress3 from '../assets/mapImages/progress3.png'
import progress4 from '../assets/mapImages/progress4.png'
import progress5 from '../assets/mapImages/progress5.png'

const requestsData = [
  {
    id: 1,
    teleDriver: 'Lily White',
    teledriverProfileImage: woman1,
    serviceId: '1',
    plateNumber: 'X7D-9K2',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:04:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '01:20',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 2,
    teleDriver: 'Mia Wilson',
    teledriverProfileImage: woman2,
    serviceId: '1',
    plateNumber: 'R3T-4C8',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:06:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '02:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 3,
    teleDriver: 'James Smith',
    teledriverProfileImage: man1,
    serviceId: '1',
    plateNumber: 'B7Y-6V4',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: ' 00:05:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '03:40',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Johnson',
    time: '10:00',
    mapImage: path3,
    progressImage: progress3,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 4,
    teleDriver: 'Avery Young',
    teledriverProfileImage: man2,
    serviceId: '1',
    plateNumber: 'W2Q-8L6',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:04:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '04:50',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: path4,
    progressImage: progress4,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 5,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: man3,
    serviceId: '1',
    plateNumber: 'G4H-2J9',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:01:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '05:10',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'David Brown',
    time: '10:00',
    mapImage: path5,
    progressImage: progress5,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 6,
    teleDriver: 'Jackson Wright',
    teledriverProfileImage: woman3,
    serviceId: '1',
    plateNumber: 'X1P-4M8',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:02:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '01:50',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Ava Chen',
    time: '10:00',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 7,
    teleDriver: 'Ava Chen',
    teledriverProfileImage: woman4,
    serviceId: '1',
    plateNumber: 'Q5Z-7T3',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:03:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '02:10',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Amelia Brown',
    time: '10:00',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 8,
    teleDriver: 'Avery Young',
    teledriverProfileImage: woman5,
    serviceId: '1',
    plateNumber: 'L3G-8H2',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:06:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '03:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Luna Martinez',
    time: '10:00',
    mapImage: path3,
    progressImage: progress3,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 9,
    teleDriver: 'Amelia Brown',
    teledriverProfileImage: woman8,
    serviceId: '1',
    plateNumber: 'V9K-6Y4',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:08:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '03:50',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Scarlett Carter',
    time: '10:00',
    mapImage: path4,
    progressImage: progress4,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 10,
    teleDriver: 'Luna Martinez',
    teledriverProfileImage: woman7,
    serviceId: '1',
    plateNumber: 'H5B-3W7',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:02:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '04:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Isabella Martinez',
    time: '10:00',
    mapImage: path5,
    progressImage: progress5,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 11,
    teleDriver: 'Scarlett Carter',
    teledriverProfileImage: woman9,
    serviceId: '1',
    plateNumber: 'N4C-2P8',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:07:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '01:40',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Lily White',
    time: '10:00',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 12,
    teleDriver: 'Isabella Martinez',
    teledriverProfileImage: woman10,
    serviceId: '1',
    plateNumber: 'Q7L-4W9',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:05:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '02:20',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Chloe Martin',
    time: '10:00',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 13,
    teleDriver: 'Lily White',
    teledriverProfileImage: woman1,
    serviceId: '1',
    plateNumber: 'D8V-6R3',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:02:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '03:10',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Aiden Jackson',
    time: '10:00',
    mapImage: path3,
    progressImage: progress3,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 14,
    teleDriver: 'Chloe Martin',
    teledriverProfileImage: woman12,
    serviceId: '1',
    plateNumber: 'J2K-8T5',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:10:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '03:50',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Olivia Jones',
    time: '10:00',
    mapImage: path4,
    progressImage: progress4,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 15,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: man4,
    serviceId: '1',
    plateNumber: 'G5Y-7B4',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:07:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '04:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Robinson',
    time: '10:00',
    mapImage: path5,
    progressImage: progress5,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 16,
    teleDriver: 'Olivia Jones',
    teledriverProfileImage: woman13,
    serviceId: '1',
    plateNumber: 'K3P-5H6',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:10:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '01:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Hall',
    time: '10:00',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 17,
    teleDriver: 'Michael Robinson',
    teledriverProfileImage: man5,
    serviceId: '1',
    plateNumber: 'Z7W-3N8',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:08:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '02:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Grace Walker',
    time: '10:00',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 18,
    teleDriver: 'Emily Hall',
    teledriverProfileImage: woman14,
    serviceId: '1',
    plateNumber: 'T5G-2Y7',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:05:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '02:40',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Daniel Perez',
    time: '10:00',
    mapImage: path3,
    progressImage: progress3,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 19,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: woman15,
    serviceId: '1',
    plateNumber: 'R8K-4C2',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:09:00',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '03:20',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Harper Anderson',
    time: '10:00',
    mapImage: path4,
    progressImage: progress4,
    cameraVideos: {
      front: video,
      back: video,
      left: video,
      right: video,
    },
  },
  {
    id: 20,
    teleDriver: 'Daniel Perez',
    teledriverProfileImage: man6,
    serviceId: '1',
    plateNumber: 'H6J-9V4',
    address: '30317 Kessler Burg, East Leda, WY...',
    duration: '00:09:30',
    connectivityStatus: 'Connected',
    status: 'Scheduled',
    waitTime: '00:00',
    etaTime: '04:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'Medium',
    taskType: 'DropOff',
    customerName: 'Mason Davis',
    time: '10:00',
    mapImage: path5,
    progressImage: progress5,
    front: video,
    back: video,
    left: video,
    right: video,
  },
  {
    id: 21,
    teleDriver: 'Daniel Perez',
    teledriverProfileImage: 'man6',
    serviceId: '1',
    plateNumber: 'BZN-628',
    address: '845 Pine St, Sparks, NV',
    duration: '00:01:05',
    connectivityStatus: 'Connected',
    status: 'Pending',
    waitTime: '01:46',
    etaTime: '08:45',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'Medium',
    taskType: 'DropOff',
    customerName: 'Emily Hall',
    time: '10:00',
    mapImage: path1,
    progressImage: progress1,
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 22,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: '1',
    plateNumber: 'SNO-167',
    address: '845 Pine St, Sparks, NV',
    duration: '00:01:05',
    connectivityStatus: 'Connected',
    status: 'Pending',
    waitTime: '02:29',
    etaTime: '07:15',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'Low',
    taskType: 'DropOff',
    customerName: 'Daniel Perez',
    time: '10:00',
    mapImage: path2,
    progressImage: progress2,
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 23,
    teleDriver: 'Daniel Perez',
    teledriverProfileImage: 'driver1',
    serviceId: '1',
    plateNumber: 'CXY-923',
    address: '845 Pine St, Sparks, NV',
    duration: '00:01:05',
    connectivityStatus: 'Connected',
    status: 'Pending',
    waitTime: '00:53',
    etaTime: '00:14',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'Low',
    taskType: 'DropOff',
    customerName: 'Daniel Perez',
    time: '10:00',
    mapImage: path3,
    progressImage: progress3,
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 24,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: '1',
    plateNumber: 'CBS-892',
    address: '845 Pine St, Sparks, NV',
    duration: '00:01:05',
    connectivityStatus: 'Connected',
    status: 'Pending',
    waitTime: '01:54',
    etaTime: '06:08',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'Low',
    taskType: 'DropOff',
    customerName: 'Emily Hall',
    time: '10:00',
    mapImage: path4,
    progressImage: progress4,
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 25,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: woman1,
    serviceId: 'CV95T43M',
    plateNumber: 'WFI-908',
    address: '66271 Franecki Ford, North Richieton',
    duration: '00:37:17',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '01:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: path5,
    progressImage: 'progress5',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 26,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: 'HXDASUWG',
    plateNumber: 'STH-725',
    address: '66271 Franecki Ford, North Richieton',
    duration: '05:10:12',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '02:53:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 27,
    teleDriver: 'James Smith',
    teledriverProfileImage: 'driver1',
    serviceId: '4XALZYN5',
    plateNumber: 'EOI-937',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:33',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '06:13:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 28,
    teleDriver: 'Mason Davis',
    teledriverProfileImage: 'driver1',
    serviceId: '85QM7PF9',
    plateNumber: 'YTX-960',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:42',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:43:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 29,
    teleDriver: 'Emily Hall',
    teledriverProfileImage: 'driver1',
    serviceId: '6GXZPL92',
    plateNumber: 'MOA-235',
    address: '66271 Franecki Ford, North Richieton',
    duration: '01:06:06',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:23:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Johnson',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 30,
    teleDriver: 'Olivia Jones',
    teledriverProfileImage: 'driver1',
    serviceId: 'OXMXKN3X',
    plateNumber: 'EMA-301',
    address: '66271 Franecki Ford, North Richieton',
    duration: '03:28:26',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '00:42:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 31,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: woman1,
    serviceId: 'CV95T43M',
    plateNumber: 'WFI-908',
    address: '66271 Franecki Ford, North Richieton',
    duration: '00:37:17',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '01:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 32,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: 'HXDASUWG',
    plateNumber: 'STH-725',
    address: '66271 Franecki Ford, North Richieton',
    duration: '05:10:12',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '02:53:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 33,
    teleDriver: 'James Smith',
    teledriverProfileImage: 'driver1',
    serviceId: '4XALZYN5',
    plateNumber: 'EOI-937',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:33',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '06:13:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 34,
    teleDriver: 'Mason Davis',
    teledriverProfileImage: 'driver1',
    serviceId: '85QM7PF9',
    plateNumber: 'YTX-960',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:42',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:43:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 35,
    teleDriver: 'Emily Hall',
    teledriverProfileImage: 'driver1',
    serviceId: '6GXZPL92',
    plateNumber: 'MOA-235',
    address: '66271 Franecki Ford, North Richieton',
    duration: '01:06:06',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:23:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Johnson',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 36,
    teleDriver: 'Olivia Jones',
    teledriverProfileImage: 'driver1',
    serviceId: 'OXMXKN3X',
    plateNumber: 'EMA-301',
    address: '66271 Franecki Ford, North Richieton',
    duration: '03:28:26',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '00:42:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 37,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: woman1,
    serviceId: 'CV95T43M',
    plateNumber: 'WFI-908',
    address: '66271 Franecki Ford, North Richieton',
    duration: '00:37:17',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '01:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 38,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: 'HXDASUWG',
    plateNumber: 'STH-725',
    address: '66271 Franecki Ford, North Richieton',
    duration: '05:10:12',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '02:53:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 39,
    teleDriver: 'James Smith',
    teledriverProfileImage: 'driver1',
    serviceId: '4XALZYN5',
    plateNumber: 'EOI-937',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:33',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '06:13:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 40,
    teleDriver: 'Mason Davis',
    teledriverProfileImage: 'driver1',
    serviceId: '85QM7PF9',
    plateNumber: 'YTX-960',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:42',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:43:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 41,
    teleDriver: 'Emily Hall',
    teledriverProfileImage: 'driver1',
    serviceId: '6GXZPL92',
    plateNumber: 'MOA-235',
    address: '66271 Franecki Ford, North Richieton',
    duration: '01:06:06',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:23:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Johnson',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 42,
    teleDriver: 'Olivia Jones',
    teledriverProfileImage: 'driver1',
    serviceId: 'OXMXKN3X',
    plateNumber: 'EMA-301',
    address: '66271 Franecki Ford, North Richieton',
    duration: '03:28:26',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '00:42:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 43,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: woman1,
    serviceId: 'CV95T43M',
    plateNumber: 'WFI-908',
    address: '66271 Franecki Ford, North Richieton',
    duration: '00:37:17',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '01:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 44,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: 'HXDASUWG',
    plateNumber: 'STH-725',
    address: '66271 Franecki Ford, North Richieton',
    duration: '05:10:12',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '02:53:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 45,
    teleDriver: 'James Smith',
    teledriverProfileImage: 'driver1',
    serviceId: '4XALZYN5',
    plateNumber: 'EOI-937',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:33',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '06:13:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 46,
    teleDriver: 'Mason Davis',
    teledriverProfileImage: 'driver1',
    serviceId: '85QM7PF9',
    plateNumber: 'YTX-960',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:42',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:43:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 47,
    teleDriver: 'Emily Hall',
    teledriverProfileImage: 'driver1',
    serviceId: '6GXZPL92',
    plateNumber: 'MOA-235',
    address: '66271 Franecki Ford, North Richieton',
    duration: '01:06:06',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:23:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Johnson',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 48,
    teleDriver: 'Olivia Jones',
    teledriverProfileImage: 'driver1',
    serviceId: 'OXMXKN3X',
    plateNumber: 'EMA-301',
    address: '66271 Franecki Ford, North Richieton',
    duration: '03:28:26',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '00:42:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 49,
    teleDriver: 'Aiden Jackson',
    teledriverProfileImage: woman1,
    serviceId: 'CV95T43M',
    plateNumber: 'WFI-908',
    address: '66271 Franecki Ford, North Richieton',
    duration: '00:37:17',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '01:30',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'John Doe',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 50,
    teleDriver: 'Grace Walker',
    teledriverProfileImage: 'driver1',
    serviceId: 'HXDASUWG',
    plateNumber: 'STH-725',
    address: '66271 Franecki Ford, North Richieton',
    duration: '05:10:12',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '02:53:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 51,
    teleDriver: 'James Smith',
    teledriverProfileImage: 'driver1',
    serviceId: '4XALZYN5',
    plateNumber: 'EOI-937',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:33',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '06:13:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 52,
    teleDriver: 'Mason Davis',
    teledriverProfileImage: 'driver1',
    serviceId: '85QM7PF9',
    plateNumber: 'YTX-960',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:42',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:43:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 53,
    teleDriver: 'Emily Hall',
    teledriverProfileImage: 'driver1',
    serviceId: '6GXZPL92',
    plateNumber: 'MOA-235',
    address: '66271 Franecki Ford, North Richieton',
    duration: '01:06:06',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '07:23:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Michael Johnson',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 54,
    teleDriver: 'Olivia Jones',
    teledriverProfileImage: 'driver1',
    serviceId: 'OXMXKN3X',
    plateNumber: 'EMA-301',
    address: '66271 Franecki Ford, North Richieton',
    duration: '03:28:26',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '00:42:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Emily Smith',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },
  {
    id: 55,
    teleDriver: 'James Smith',
    teledriverProfileImage: 'driver1',
    serviceId: '4XALZYN5',
    plateNumber: 'EOI-937',
    address: '66271 Franecki Ford, North Richieton',
    duration: '02:01:33',
    connectivityStatus: 'Connected',
    status: 'Active',
    waitTime: '00:00',
    etaTime: '06:13:00',
    startPoint: '66271 Franecki Ford, North Richieton',
    endPoint: '66271 Franecki Ford, North Richieton',
    priority: 'High',
    taskType: 'DropOff',
    customerName: 'Sarah Williams',
    time: '10:00',
    mapImage: '',
    progressImage: '',
    cameraVideos: {
      front: 'video',
      back: 'video',
      left: 'video',
      right: 'video',
    },
  },

]

export default requestsData;
