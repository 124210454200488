// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requestActionPopup {
  background: rgb(32, 36, 55);
  right: 0px;
  border-radius: 10px;
  z-index: 1;
  position: absolute;
  width: 200px;
}
.requestActionPopup ul.MuiList-root {
  margin-top: 0px;
}
.requestActionPopup ul.MuiList-root li {
  padding: 12px 10px;
  font-size: 14px;
  line-height: 22px;
  font-family: "Poppins";
  font-weight: 400;
  border-bottom: 1px solid rgb(40, 48, 76);
}
.requestActionPopup ul.MuiList-root li:last-child {
  border: none;
}
.requestActionPopup .headerSection {
  color: rgba(255, 255, 255, 0.4);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/RequestsSection/RequestActionPopup/styles.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,UAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;AACR;AACQ;EACI,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,wCAAA;AACZ;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,+BAAA;EACA,YAAA;AAFR","sourcesContent":[".requestActionPopup {\r\n    background: rgba(32, 36, 55, 1);\r\n    right: 0px;\r\n    border-radius: 10px;\r\n    z-index: 1;\r\n    position: absolute;\r\n    width: 200px;\r\n\r\n    ul.MuiList-root {\r\n        margin-top: 0px;\r\n\r\n        li {\r\n            padding: 12px 10px;\r\n            font-size: 14px;\r\n            line-height: 22px;\r\n            font-family: 'Poppins';\r\n            font-weight: 400;\r\n            border-bottom: 1px solid rgba(40, 48, 76, 1);\r\n        }\r\n\r\n        li:last-child {\r\n            border: none;\r\n        }\r\n    }\r\n\r\n    .headerSection {\r\n        color: rgba(255, 255, 255, 0.4);\r\n        border: none;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
