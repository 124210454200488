import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { actions } from '../../redux/Dashboard/slice';
import { AlertsData, RequestsData } from '../../redux/Dashboard/rootState';
import Alerts from './AlertSection';
import PerformanceIndicatorSection from './PerformanceIndicatorSection';
import ActivitySection from './ActivitySection';
import TeleDriversSection from './TeleDriversSection';
import SessionInfoPanelSection from './SessionInfoPanelSection';
import RequestSection from './RequestsSection';
import OperatorBehaviourPopupSection from '../common/OperatorBehaviourPopupSection';

function Dashboard() {
  const dispatch = useDispatch();
  const [sessionInfoPanelData, setSessionInfoPanelData] = useState<AlertsData | RequestsData>();
  const [sessionInfoPanel, setSessionInfoPanel] = useState(false);
  const [operatorBehaviourState, setOperatorBehaviourState] = useState(false);
  const scenario = useAppSelector((state) => state.dashboardSlice.scenario);

  const openAlertsPopUp = (val: AlertsData | RequestsData) => {
    if (val) {
      setSessionInfoPanel(true);
      setSessionInfoPanelData(val);
    }
  };

  const closeAlertsPopUp = (value: boolean) => {
    setSessionInfoPanel(value);
    const val: HTMLElement | null = document.getElementById(`request_${sessionInfoPanelData?.id}`);
    const val1: HTMLElement | null = document.getElementById(`alerts_${sessionInfoPanelData?.id}`);
    if (val?.classList.contains('selected')) {
      val.classList.remove('selected');
    } else if (val1?.classList.contains('selected')) {
      val1.classList.remove('selected');
    }
  };

  const onCloseHandleEvent = (value: boolean) => {
    setOperatorBehaviourState(value)
  }

  useEffect(() => {
    if (scenario === 1) {
      setOperatorBehaviourState(true);
    }
  }, [scenario]);

  useEffect(() => {
    dispatch(actions.getScenario());
  }, []);

  return (
    <Box className="middle-main-content">
      <Box className="middle-content">
        <PerformanceIndicatorSection />
        <Alerts parentFunction={openAlertsPopUp} />
        {
          sessionInfoPanel ? (
            <SessionInfoPanelSection sessionInfoPanelData={sessionInfoPanelData!} parentFunction={closeAlertsPopUp} />
          ) : ('')
        }
        <RequestSection handleComponentCalling={openAlertsPopUp} />
        {
          operatorBehaviourState ? (<OperatorBehaviourPopupSection parentFunctionCalling={onCloseHandleEvent} />) : ('')
        }
      </Box>
      <Box className="right-main-content">
        <ActivitySection />
        <TeleDriversSection />
      </Box>
    </Box>
  );
}

export default Dashboard;
