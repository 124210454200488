import alertsData from '../../data/alertsData';
import { TeleOperatorStatus } from '../../utils/enums';
import ScoreIcon from '../../assets/images/Score.png';
import SaticfactionGraph from '../../assets/images/SaticfactionGraph.png';
import efficiencyIcon from '../../assets/images/Efficiency.png';
import efficiencyGraph from '../../assets/images/EfficiencyGraph.png';
import timeIcon from '../../assets/images/Time.png';
import timerIcon from '../../assets/images/Timer.png';

export const indicatorData = [
  {
    rate: '8.4',
    perRate: '10',
    icon: ScoreIcon,
    indicatorTitle: 'Satisfaction Score',
    indicatorImage: SaticfactionGraph,
  },
  {
    rate: '7.6',
    perRate: '10',
    icon: efficiencyIcon,
    indicatorTitle: 'Efficiency',
    indicatorImage: efficiencyGraph,
  },
  {
    rate: '10:32',
    perRate: '',
    icon: timeIcon,
    indicatorTitle: 'Avg. Session Time',
    indicatorImage: '',
  },
  {
    rate: '01:05',
    perRate: '',
    icon: timerIcon,
    indicatorTitle: 'Avg. Wait Time',
    indicatorImage: '',
  },
];

export const sessionList = [{
  status: 'Pending',
  vehicleId: 'b519212f-ba95-41c4-9ed8-b3431e4b26fb',
}];

export const initialState = {
  loading: false,
  error: '',
  selectedOperatorStatus: TeleOperatorStatus.ALL,
  indicatorData,
  requestData: [],
  alertsData: [],
  resetSlide: false,
  resetRequestCount: false,
  scenario: 0,
  sessionList: [],
};
