import {
  all, call, delay, put, takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { actions } from './slice';
import { apiEndPoints, constant, path } from '../../utils/constant';
import { SessionList } from './rootState';
import { RequestsStatus, SessionsStatus } from '../../utils/enums';

function* FetchSessionList() {
  try {
    const response: AxiosResponse = yield call(
      axios.get<SessionList[]>,
      `${process.env.REACT_APP_API_BASE_URL + apiEndPoints.GETLIST}`,
      { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } },
    );

    const dataWithLastQueuedEvent = response.data.filter((item: { flowEventDisplay: string | any[]; }) => {
      const lastEvent = item.flowEventDisplay[item.flowEventDisplay.length - 1];
      return lastEvent && (lastEvent.flowEvent === SessionsStatus.QUEUED || lastEvent.flowEvent === SessionsStatus.ESTABLISHED);
    });

    const queuedEvents = dataWithLastQueuedEvent.map((item: { flowEventDisplay: string | any[]; vehicleId: any; }) => {
      const lastEvent = item.flowEventDisplay[item.flowEventDisplay.length - 1];
      return {
        status: lastEvent.flowEvent === SessionsStatus.QUEUED ? RequestsStatus.PENDING : RequestsStatus.ACTIVE,
        vehicleId: item.vehicleId,
      };
    });

    if (queuedEvents.length > 0) {
      yield put(actions.getVehicleSessionListSuccess(queuedEvents));
    } else {
      yield put(actions.getVehicleSessionListFailed(new Error(constant.NODATA)));
    }
  } catch (err) {
    yield put(actions.getVehicleSessionListFailed(new Error(constant.NOREQUEST)));
  }
}

function* PollFetchSessionList() {
  let url = window.location.href;
  while (url.includes(path.DASHBOARD)) {
    yield call(FetchSessionList);
    yield delay(200);
    url = window.location.href;
  }
}

function* GetScenario() {
  try {
    const response: AxiosResponse = yield call(
      axios.get,
      process.env.REACT_APP_POPUP_SCENARIO_API_URL!,
    );
    yield put(actions.setScenario(response?.data?.scenario));
  } catch (err) {
    console.log('Error in Fetching Scenario: ', err);
  }
}

function* PollGetScenario() {
  let url = window.location.href;
  while (url.includes(path.DASHBOARD)) {
    // yield call(GetScenario);
    yield delay(1000);
    url = window.location.href;
  }
}

export default function* DashboardSaga() {
  yield all([
    takeLatest(actions.getVehicleSessionList, PollFetchSessionList),
    takeLatest(actions.getScenario, PollGetScenario),
  ]);
}
