import { Box, Grid } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import './styles.scss';

function PerformanceIndicatorSection() {
  const indicatorData = useAppSelector((state) => state.dashboardSlice.indicatorData);
  return (
    <Box className="indicatorSection">
      <Grid container>
        {indicatorData.map((data, index) => (
          <Grid item xs={3}>
            <Box>
              <span className="dataRate">{data.rate}</span>
              <span className="dataPerRate">{data.perRate ? `/${data.perRate}` : ''}</span>
              <span className="dataTitle">
                <span className="image-wrapper"><img src={data.icon} alt={data.indicatorTitle} /></span>
                <span>{data.indicatorTitle}</span>
              </span>
            </Box>
            <Box className="right-image-wrapper">
              {data.indicatorImage ? <img src={data.indicatorImage} alt={data.indicatorTitle} /> : ''}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PerformanceIndicatorSection;
