import {
  Box, List, ListItem,
} from '@mui/material';
import { Position } from '../../../../redux/Dashboard/rootState';
import { constant } from '../../../../utils/constant';
import './styles.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
  requestActionPopup: boolean;
}

function RequestActionPopup({
  isOpen, onClose, position, requestActionPopup,
}: ModalProps) {
  if (!isOpen) {
    return null;
  }

  const { top, right } = position;
  let style: any;
  let newTopPosition = top;
  let newLeftPosition = right;
  const bottom = window.innerHeight - top;
  if (!requestActionPopup) {
    newTopPosition = top + 5;
    newLeftPosition = right - 160;
    if (bottom < 200) {
      style = { bottom: '25px', left: `${newLeftPosition}px` }
    } else {
      style = { top: `${newTopPosition}px`, left: `${newLeftPosition}px` }
    }
  } else if (bottom < 200) {
    style = { bottom: '25px', right: `${50}px` }
  } else {
    style = { top: `${newTopPosition}px`, right: `${50}px` }
  }

  const handleActionBtnClick = (e: any) => {
    e.stopPropagation();
  }
  return (
    <Box
      className={`requestActionPopup ${isOpen ? 'show' : 'hide'}`}
      style={style}
    >
      <List onClick={(event) => handleActionBtnClick(event)}>
        <ListItem className="headerSection">
          {constant.ACTION}
        </ListItem>
        <ListItem>
          {constant.REASSIGN}
        </ListItem>
        <ListItem>
          {constant.SEEONMAP}
        </ListItem>
        <ListItem>
          {constant.CALLDRIVER}
        </ListItem>
      </List>
    </Box>
  )
}
export default RequestActionPopup;
