import {
  Box, Button, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { RequestsData } from '../../../../redux/Dashboard/rootState';
import RequestActionPopup from '../RequestActionPopup';
import { useAppSelector } from '../../../../store/hooks';
import {
  GetConnctionStatusImage, GetHoveredImage, GetMonitorButtonText,
} from '../../../../utils/helper';
import SessionTimer from '../../../common/SessionTimer';
import { RequestsStatus } from '../../../../utils/enums';
import { constant } from '../../../../utils/constant';
import './styles.scss';

interface Active {
  parentFunction: (val: RequestsData) => void;
}

function ActiveSession({ parentFunction }: Active) {
  const [requests, setRequests] = useState<RequestsData[]>([]);
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [hoveredMonitorRowId, setHoveredMonitorRowId] = useState<number | null>(null);
  const [popupStates, setPopupStates] = useState<boolean[]>([]);
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
  const newRef = useRef<HTMLElement>(null);
  const requestData = useAppSelector((state) => state.dashboardSlice.requestData);
  const requestActionPopup: boolean = false;

  const onClose = (index: number) => {
    const newPopupStates = [...popupStates];
    newPopupStates[index] = false;
    setPopupStates(newPopupStates);
  };

  const handleOutsideClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      onClose(Number(newRef?.current?.id));
    }
  };

  useEffect(() => {
    setRequests(requestData.filter((item: RequestsData) => item.status === RequestsStatus.ACTIVE));
    setPopupStates(Array(requestData.length).fill(false));
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [requestData]);

  const handleMouseEnter = (rowId: number) => {
    setHoveredRowId(rowId);
  }

  const handleMouseLeave = () => {
    setHoveredRowId(null);
  }

  const handleMonitorMouseEnter = (rowId: number) => {
    setHoveredMonitorRowId(rowId);
  }

  const handleMonitorMouseLeave = () => {
    setHoveredMonitorRowId(null);
  }

  const handleActionBtnClick = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top: rect.top, right: rect.left });
    const newPopupStates = [...popupStates];
    newPopupStates[index] = !newPopupStates[index];
    setPopupStates(newPopupStates);
  };

  const openSessionInfoPanel = (request: any) => {
    parentFunction(request);
    const val: HTMLElement | null = document.getElementById(`request_${request.id}`);
    if (val) {
      val.classList.add('selected');
    }
  }

  return (
    <Box className="activeSection">
      <Table className="table">
        <TableHead className="table-head">
          <TableRow className="table-row">
            <TableCell className="header-cell teledriver-header">{constant.TELEDRIVERHEAD}</TableCell>
            <TableCell className="header-cell duration">{constant.DURATION}</TableCell>
            <TableCell className="header-cell plateNumber">{constant.PLATENUMBER}</TableCell>
            <TableCell className="header-cell service-id">{constant.SERVICEID}</TableCell>
            <TableCell className="header-cell connectivity">{constant.CONNECTIVITY}</TableCell>
            <TableCell className="button-column header-cell" />
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {
            (requests && requests.length > 0)
              ? requests?.map((request: RequestsData, index: number) => (
                <>
                  <TableRow className="table-row" key={request.id} id={`request_${request.id}`} onClick={() => openSessionInfoPanel(request)} style={{ cursor: 'pointer' }}>
                    <TableCell className="body-cell teledriverName">
                      {request.teleDriver}
                    </TableCell>
                    <TableCell className="body-cell duration">
                      <SessionTimer timer={request.duration} />
                    </TableCell>
                    <TableCell className="body-cell">
                      {request.plateNumber}
                    </TableCell>
                    <TableCell className="body-cell">
                      {request.serviceId}
                    </TableCell>
                    <TableCell className="body-cell connectivity">
                      <img src={GetConnctionStatusImage(request.connectivityStatus)} alt={request.connectivityStatus} />
                    </TableCell>
                    <TableCell className="body-cell actionCell">
                      <Button
                        className="actionButton"
                        onMouseEnter={() => handleMouseEnter(request.id)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(event) => handleActionBtnClick(index, event)}
                      >
                        <img src={GetHoveredImage(request.id, hoveredRowId!)} alt="Action" />
                      </Button>
                      <Button
                        className="monitorButton"
                        onMouseEnter={() => handleMonitorMouseEnter(request.id)}
                        onMouseLeave={handleMonitorMouseLeave}
                      >
                        <span className={hoveredMonitorRowId === request.id ? 'orange' : 'white'}>{constant.MONITOR}</span>
                        <img src={GetMonitorButtonText(request.id, hoveredMonitorRowId!)} alt={constant.MONITOR} />
                      </Button>
                      {popupStates[index] && (
                        <Box ref={newRef} id={index.toString()}>
                          <RequestActionPopup requestActionPopup={requestActionPopup} isOpen={popupStates[index]} onClose={() => onClose(index)} position={popupPosition} />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                  {(requests && requests.length - 1 !== index)
                    ? <Box className="row-gap" />
                    : null}
                </>
              ))
              : (
                <Box className="noData">
                  {constant.NOACTIVE}
                </Box>
              )
          }
        </TableBody>
      </Table>
    </Box>
  )
}
export default ActiveSession;
