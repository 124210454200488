import { Box } from '@mui/material';
import ActivityGraphImage from '../../../assets/images/ActivityGraph.png';
import './styles.scss';

function ActivitySection() {
  return (
    <Box className="activitySection">
      <img src={ActivityGraphImage} alt="ActivityGraphImage" />
    </Box>
  );
}

export default ActivitySection;
